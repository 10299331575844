import React from 'react';

import Layout from '../components/layout';
import Container from '../components/container';

const Index = ({}) => {
  return (
    <Layout title='About - Cotera'>
      <Container>
        <div className='bg-white min-h-screen'>
          <div className='mx-auto pt-12 pb-24 px-4 max-w-7xl sm:px-6 lg:px-8 '>
            <div className='space-y-12'>
              <div className='mx-auto space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl '>
                <h2 className='text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl pt-4'>
                  Security at Cotera
                </h2>
                Cotera takes security very seriously and is currently undergoing
                SOC2 certification. We are happy to provide information on the
                full suite of policies and controls we implement, but here are
                some highlights:
                <h3 className='text-center text-xl font-bold tracking-tight text-gray-900 pt-4'>
                  Security program highlights
                </h3>
                <Highlight
                  header='Data Security'
                  body='All data is encrypted at rest and in transit. We rely on Google Clouds Key Management to encrypt our keys and require the use of the latest encryption standards such as AES-256.'
                />
                <Highlight
                  header='Application Security'
                  body="We follow a rigorous and audited secure development process. Additionally, we use static security analysis tools such as Dependabot and Google's Container Scanning service to secure our product at every step of the development process"
                />
                <Highlight
                  header='Infrastructure Security'
                  body="Cotera uses Google Cloud Platform to host our application. We make use of GCP's Kuberetes engine to ensure that all of the latest hardening standards are in place."
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
};

export default Index;

const Highlight: React.FC = ({ header, body }) => {
  return (
    <div>
      <h3 className='text-lg font-semibold text-gray-800 mb-2'>{header}</h3>
      <p>{body}</p>
    </div>
  );
};
